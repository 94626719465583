import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "program" }
const _hoisted_2 = { cellpadding: "5" }
const _hoisted_3 = {
  colspan: "2",
  style: {"text-align":"center"}
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { colspan: "2" }
const _hoisted_6 = { class: "scheduled-time" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["href"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { colspan: "2" }
const _hoisted_12 = { class: "scheduled-time" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["href"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["href"]
const _hoisted_19 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Program", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Schedule", -1)),
            _cache[1] || (_cache[1] = _createTextVNode(" (All talks are taken at ")),
            _createElementVNode("a", {
              href: _ctx.seminarLocation.link
            }, _toDisplayString(_ctx.seminarLocation.room), 9, _hoisted_4),
            _createElementVNode("span", null, ", " + _toDisplayString(_ctx.seminarLocation.description) + ")", 1)
          ])
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.from), 1)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.firstDaySchedule, (event) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: event.time
          }, [
            _createElementVNode("td", _hoisted_6, _toDisplayString(event.time), 1),
            _createElementVNode("td", null, [
              (event.isSpeech)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                    (_ctx.isReleased)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                          _createElementVNode("a", {
                            href: event.videoUrl
                          }, [
                            _createElementVNode("strong", null, _toDisplayString(event.activity), 1)
                          ], 8, _hoisted_9),
                          _createTextVNode(", " + _toDisplayString(event.title), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(event.activity), 1))
            ])
          ]))
        }), 128)),
        _cache[2] || (_cache[2] = _createElementVNode("tr", null, [
          _createElementVNode("th", { colspan: "2" }, " ")
        ], -1)),
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.to), 1)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.secondDaySchedule, (event) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: event.time
          }, [
            _createElementVNode("td", _hoisted_12, _toDisplayString(event.time), 1),
            _createElementVNode("td", null, [
              (event.isSpeech)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                    (_ctx.isReleased)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                          _createElementVNode("a", {
                            href: event.videoUrl
                          }, [
                            _createElementVNode("strong", null, _toDisplayString(event.activity), 1)
                          ], 8, _hoisted_15),
                          _createTextVNode(", " + _toDisplayString(event.title), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(event.activity), 1))
            ])
          ]))
        }), 128))
      ])
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "divider" }, null, -1)),
    _createElementVNode("h1", null, [
      _cache[3] || (_cache[3] = _createTextVNode("Titles and Abstracts ")),
      (_ctx.isReleased)
        ? (_openBlock(), _createElementBlock("span", _hoisted_17))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.speechesOrdered, (speech) => {
        return (_openBlock(), _createElementBlock("div", {
          key: speech.time
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("a", {
              href: speech.videoUrl
            }, [
              _createElementVNode("strong", null, _toDisplayString(speech.activity), 1),
              _createTextVNode(", " + _toDisplayString(speech.title), 1)
            ], 8, _hoisted_18)
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "divider" }, null, -1)),
          _createElementVNode("p", {
            innerHTML: _ctx.renderedAbstract(speech.abstract),
            class: "abstract-content"
          }, null, 8, _hoisted_19)
        ]))
      }), 128))
    ])
  ], 64))
}
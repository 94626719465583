
import { defineComponent } from "vue";
import config from "@/config.json";
import katex from "katex";
import "katex/dist/katex.min.css";

export default defineComponent({
  setup() {
    const renderedAbstract = (abstract: string) => {
      const formattedAbstract = abstract
        .replace(/\n/g, " <br> ")
        .replace(/\$(.*?)\$/g, (_, match) =>
          katex.renderToString(match, {
            throwOnError: false,
            displayMode: false,
            output: "html",
          })
        )
        .replace(/\$\$(.*?)\$\$/g, (_, match) =>
          katex.renderToString(match, {
            throwOnError: false,
            displayMode: true,
            output: "html",
          })
        );

      return formattedAbstract;
    };
    const formatter = new Intl.DateTimeFormat("us", {
      month: "long",
      year: "numeric",
      day: "numeric",
      weekday: "long",
    });

    const from = formatter.format(new Date(config.duration.from));
    const to = formatter.format(new Date(config.duration.to));

    const firstDaySchedule = config.schedule.firstDay;
    const secondDaySchedule = config.schedule.secondDay;
    const speeches = [...firstDaySchedule, ...secondDaySchedule].filter(
      (i) => i.isSpeech
    );

    const speechesOrdered = speeches.sort((a, b) =>
      a.activity
        .split(" ")
        .slice(-1)[0]
        .localeCompare(b.activity.split(" ").slice(-1)[0])
    );

    return {
      seminarLocation: config.location,
      from,
      to,
      firstDaySchedule,
      secondDaySchedule,
      speeches,
      speechesOrdered,
      isReleased: true,
      renderedAbstract,
    };
  },
});

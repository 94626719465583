import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Dates and Location", -1)),
      _createElementVNode("p", null, " The " + _toDisplayString(_ctx.numberOfSeminar) + " SCGAS will be held at the University of California Irvine on " + _toDisplayString(_ctx.from) + " and " + _toDisplayString(_ctx.to) + ". ", 1)
    ]),
    _createElementVNode("div", null, [
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Speakers", -1)),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.speakers, (speaker) => {
          return (_openBlock(), _createElementBlock("li", {
            key: speaker.name
          }, [
            _createElementVNode("a", {
              href: speaker.link
            }, _toDisplayString(speaker.name), 9, _hoisted_1),
            _createTextVNode(" (" + _toDisplayString(speaker.institute) + ") ", 1)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", null, [
      _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Registration", -1)),
      _createElementVNode("p", null, [
        _cache[2] || (_cache[2] = _createTextVNode(" Conference participants are requested to register in advance using the ")),
        _createElementVNode("a", {
          href: _ctx.registrationUrl,
          target: "_blank"
        }, "online registration form", 8, _hoisted_2),
        _cache[3] || (_cache[3] = _createTextVNode(". "))
      ]),
      _createElementVNode("p", null, [
        _cache[4] || (_cache[4] = _createTextVNode(" There will be $15 registration fee to cover refreshments during the Saturday morning registration. ")),
        _createElementVNode("a", {
          href: _ctx.feeUrl,
          target: "_blank"
        }, " Please pay the fee here using your credit card.", 8, _hoisted_3)
      ])
    ]),
    _createElementVNode("div", null, [
      _cache[6] || (_cache[6] = _createElementVNode("h1", null, "Conference Support", -1)),
      _cache[7] || (_cache[7] = _createElementVNode("p", null, " The SCGAS conferences have been continuously funded by the National Science Foundation. ", -1)),
      _createElementVNode("p", null, " The NSF funds provide support for the travel and conference expenses of participants with priority given to graduate students and recent Ph.D's. If you would like to apply for support, please register and request financial support using the online registration form before " + _toDisplayString(_ctx.registrationEndsAt) + ". ", 1)
    ]),
    _createElementVNode("div", null, [
      _cache[8] || (_cache[8] = _createElementVNode("h1", null, "Organizing Committee", -1)),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizingCommittee, (member) => {
          return (_openBlock(), _createElementBlock("li", {
            key: member.name
          }, [
            _createElementVNode("a", {
              href: member.link
            }, _toDisplayString(member.name), 9, _hoisted_4),
            _createTextVNode(" (" + _toDisplayString(member.institute) + ") ", 1)
          ]))
        }), 128))
      ])
    ])
  ], 64))
}
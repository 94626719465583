import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "reimbursement-document hover-shadow" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Instructions: ", -1)),
    _cache[1] || (_cache[1] = _createTextVNode(" please fill in all required forms and send them back to Ingrid Richey (")),
    _cache[2] || (_cache[2] = _createElementVNode("a", { href: "mailto:irichey@uci.edu" }, "irichey@uci.edu", -1)),
    _cache[3] || (_cache[3] = _createTextVNode("). ")),
    _createElementVNode("ol", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documents, (document) => {
        return (_openBlock(), _createElementBlock("li", { key: document }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("a", {
              href: document,
              target: "_blank"
            }, _toDisplayString(document.split("/")[1].split(".")[0].replaceAll("_", " ")), 9, _hoisted_2)
          ])
        ]))
      }), 128))
    ])
  ], 64))
}
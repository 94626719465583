import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "sider" }
const _hoisted_2 = { style: {"border-bottom":"1px solid #000000"} }

import { routes as rawRoutes } from "@/router";

export default /*@__PURE__*/_defineComponent({
  __name: 'AppSider',
  setup(__props) {

const routes = rawRoutes.filter((e) => !e.hidden);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(routes), (route) => {
      return (_openBlock(), _createElementBlock("div", {
        key: route.name
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_RouterLink, {
            class: "nav-item hover-shadow",
            to: route.path
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(route.name), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ])
      ]))
    }), 128))
  ]))
}
}

})
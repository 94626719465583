import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "participants" }
const _hoisted_2 = { cellpadding: "5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Participants", -1)),
    _cache[2] || (_cache[2] = _createTextVNode(" (Updated on 12/01/2024. Due to phishing attempts, the list will only contain speakers and organizers. The full participant list will be posted after the SCGAS event.) ")),
    _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
    _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
    _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, "Last Name"),
            _createElementVNode("th", null, "First Name"),
            _createElementVNode("th", null, "Institute")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.participants, (participant) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: participant.name
            }, [
              _createElementVNode("td", null, [
                _createElementVNode("strong", null, _toDisplayString(participant.name.split(" ").pop()), 1)
              ]),
              _createElementVNode("td", null, _toDisplayString(participant.name.split(" ").slice(0, -1).join(" ")), 1),
              _createElementVNode("td", null, _toDisplayString(participant.institute), 1)
            ]))
          }), 128))
        ])
      ])
    ])
  ], 64))
}

import { defineComponent } from "vue";
import config from "@/config.json";
import {
  getSortedOrganizingCommittee,
  getSortedSpeakers,
  ordinalize,
} from "@/utils";

const numberOfSeminar = ordinalize(config.number);
const formatter = new Intl.DateTimeFormat("us", {
  month: "long",
  year: "numeric",
  day: "numeric",
  weekday: "long",
});
const from = formatter.format(new Date(config.duration.from));
const to = formatter.format(new Date(config.duration.to));
const speakers = getSortedSpeakers();
const registrationUrl = config.registrationUrl;
const feeUrl = config.feeUrl;
const registrationEndsAt = formatter.format(
  new Date(config.registrationEndsAt)
);
const organizingCommittee = getSortedOrganizingCommittee();

export default defineComponent({
  setup() {
    return {
      numberOfSeminar,
      from,
      to,
      speakers,
      registrationUrl,
      feeUrl,
      registrationEndsAt,
      organizingCommittee,
    };
  },
});

import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { id: "restaurants" }
const _hoisted_2 = { cellpadding: "5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Accommodation", -1)),
    _cache[3] || (_cache[3] = _createElementVNode("p", null, " Lodging can be found at various hotels which offer a special UCI corporate rate (inquire when you reserve), including ", -1)),
    _cache[4] || (_cache[4] = _createElementVNode("table", { cellpadding: "5" }, [
      _createElementVNode("col", { width: "30%" }),
      _createElementVNode("col", { width: "70%" }),
      _createElementVNode("tr", null, [
        _createElementVNode("td", null, [
          _createElementVNode("b", null, [
            _createElementVNode("a", {
              class: "hover-shadow",
              target: "_blank",
              href: "https://www.ayreshotels.com/"
            }, "Ayers Hotel at Costa Mesa")
          ])
        ]),
        _createElementVNode("td", null, "The code for getting the University rate $139+tax is UCI-SCGAS.")
      ]),
      _createElementVNode("tr", null, [
        _createElementVNode("td", null, [
          _createElementVNode("b", null, [
            _createElementVNode("a", {
              class: "hover-shadow",
              target: "_blank",
              href: "https://atriumhotel.com/"
            }, "Atrium Hotel")
          ])
        ]),
        _createElementVNode("td", null, "Mention \"UCI\"")
      ]),
      _createElementVNode("tr", null, [
        _createElementVNode("td", null, [
          _createElementVNode("b", null, [
            _createElementVNode("a", {
              class: "hover-shadow",
              target: "_blank",
              href: "https://www.hyatt.com/en-US/hotel/california/hyatt-regency-john-wayne-airport-newport-beach/snarj"
            }, " Hyatt Regency ")
          ])
        ]),
        _createElementVNode("td", null, "Mention \"UCI\"")
      ])
    ], -1)),
    _cache[5] || (_cache[5] = _createElementVNode("p", null, null, -1)),
    _cache[6] || (_cache[6] = _createElementVNode("h1", null, "Restaurants", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("tr", null, [
          _createElementVNode("th", {
            colspan: "2",
            style: {"font-size":"20px"}
          }, "Lunch")
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lunchOptions, (lunch) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: lunch.name
          }, [
            _createElementVNode("td", null, [
              _createElementVNode("strong", null, _toDisplayString(lunch.name), 1)
            ]),
            _createElementVNode("td", null, _toDisplayString(lunch.description), 1)
          ]))
        }), 128)),
        _cache[1] || (_cache[1] = _createElementVNode("tr", null, [
          _createElementVNode("th", {
            colspan: "2",
            style: {"font-size":"20px"}
          }, "Dinner Near UC Irvine")
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dinnerOptions, (dinner) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: dinner.name
          }, [
            _createElementVNode("td", null, [
              _createElementVNode("strong", null, _toDisplayString(dinner.name), 1)
            ]),
            _createElementVNode("td", null, _toDisplayString(dinner.description), 1)
          ]))
        }), 128))
      ])
    ])
  ], 64))
}
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[6] || (_cache[6] = _createElementVNode("h1", null, "Transportation", -1)),
    _cache[7] || (_cache[7] = _createElementVNode("h2", null, "Airports", -1)),
    _cache[8] || (_cache[8] = _createElementVNode("p", null, " The University of California at Irvine is centrally located in southern California. The most convenient and nearby airport is the John Wayne Orange County Airport (SNA) which is about a short 10-15 minute drive from campus. Most hotels in Irvine have shuttle services which can pick you up directly from SNA. ", -1)),
    _cache[9] || (_cache[9] = _createElementVNode("p", null, [
      _createTextVNode(" Two alternative airports are the Long Beach Airport (LGB), about a 30-minute drive from campus, and the Los Angeles International Airport (LAX), about a 50-minute drive away. If arriving from LAX, taxi service is available to Irvine which will cost approximately $100. Also there are many shuttle services which can take you from LAX to Irvine, for instance the "),
      _createElementVNode("a", {
        href: "https://www.supershuttle.com/",
        target: "_blank"
      }, "Super Shuttle"),
      _createTextVNode(". ")
    ], -1)),
    _cache[10] || (_cache[10] = _createElementVNode("h2", null, "Campus Map and Parking", -1)),
    _createElementVNode("p", null, [
      _cache[0] || (_cache[0] = _createTextVNode(" The talks will take place in the ")),
      _createElementVNode("a", {
        href: _ctx.seminarLocation.link
      }, _toDisplayString(_ctx.seminarLocation.room), 9, _hoisted_1),
      _cache[1] || (_cache[1] = _createTextVNode(" which you can locate on the ")),
      _cache[2] || (_cache[2] = _createElementVNode("a", {
        href: "https://web.communications.uci.edu/assets/email/dfa-tds/TDS-Website/maps/Main_UCI-Map-REV012520-24.pdf",
        target: "_blank"
      }, "campus map (Building 419, Coordinates F5)", -1)),
      _cache[3] || (_cache[3] = _createTextVNode(". "))
    ]),
    _createElementVNode("p", null, [
      _createTextVNode(" The parking structure that is closest to " + _toDisplayString(_ctx.seminarLocation.room) + " is Parking Lot 16, with parking dispensor. Single-day parking permits are also available for purchase online. On weekends, you can park in the general parking spots with $13 one-day permit. For participants from other UC campuses, the parking permit from your home institution may be valid at UC Irvine. Please see the ", 1),
      _cache[4] || (_cache[4] = _createElementVNode("a", {
        href: "http://www.parking.uci.edu/permits/visitorpermits.cfm",
        target: "_blank"
      }, "UC Irvine Visitor Parking", -1)),
      _cache[5] || (_cache[5] = _createTextVNode(" website or call the parking office at (949) 824-7275 for more information. "))
    ])
  ], 64))
}
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "header" }
const _hoisted_2 = { style: {"margin-bottom":"18px"} }

import config from "@/config.json";
import { ordinalize } from "@/utils/index";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  setup(__props) {

const numberOfSeminar = ordinalize(config.number);
const formatter = new Intl.DateTimeFormat("us", {
  month: "long",
  year: "numeric",
  day: "numeric",
});
const from = formatter.format(new Date(config.duration.from));
const to = formatter.format(new Date(config.duration.to));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, " The " + _toDisplayString(_unref(numberOfSeminar)) + " Southern California Geometric Analysis Seminar ", 1),
    _cache[0] || (_cache[0] = _createElementVNode("h2", null, "University of California, Irvine", -1)),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_unref(from)) + " - " + _toDisplayString(_unref(to)), 1)
  ]))
}
}

})
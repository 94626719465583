import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "wrapper" }
const _hoisted_2 = { id: "main-content" }

import AppHeader from "@/components/AppHeader.vue";
import AppSider from "./AppSider.vue";
import AppContent from "./AppContent.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'AppLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppHeader),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "divider" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(AppSider, { style: {"flex":"2"} }),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider" }, null, -1)),
      _createVNode(AppContent, { style: {"flex":"7"} }, {
        default: _withCtx(() => [
          _createVNode(_component_RouterView)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})